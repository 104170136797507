import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import salesForceOAuth2 from './reducers/salesforceOAuth2'

// move to index?
const test_prod = false
const forceSanbox = true

// move to index?
let oauth2 = salesForceOAuth2({
  response_type: 'token',
  scope: 'web api id',
  oauth2: {
    // TODO move to env vars for deploy
    // you can change loginUrl to connect to sandbox or prerelease env.
    loginUrl: 'https://pc-chtc.cs138.force.com/APIConnectionPortal',
    // loginUrl: (process.env.NODE_ENV === 'development' && !test_prod) ? 'https://test.salesforce.com' : 'https://login.salesforce.com',
    // loginUrl: 'https://chtc-ctlc--pc.my.salesforce.com',
    clientId: forceSanbox || process.env.NODE_ENV === 'development' && !test_prod ? '3MVG9SUK8FGR3O.tJC0xL_RuyKrAj1GPEpQx.beFIT.5PLDTerGvqdjF_FNQIKH1.nCMRn9M91eLCdn6L8Ry3' : '3MVG9nkapUnZB56EDSefar0nDpISbc7lEwJCEA8XaDZ.4aNq9EBTg8pV0.dzHqAcaF3Ktdnc6Qm12MRWEh6MB',
    clientSecret: forceSanbox || process.env.NODE_ENV === 'development' && !test_prod ? 'D4EA1E97CD671C866BE2501036C6CB9C17027F434BB3073891863687EB1C8390' : '44B131A2128A2D607DF22ADB9DF75F5C0D62EECB8769FDEF0A148D92A523CBD3',
    redirectUri: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://satool.chtc.shiva.magnaro.com'
  }
})
export default configureStore({
  reducer: {
    counter: counterReducer,
    oauth2: oauth2.reducer
  },
});

export { oauth2, test_prod}
