/**
 * Created by Michał Stawski on 10.02.2020.
 */
import jsforce from 'jsforce'
import {oauth2, test_prod } from '../store'

const initialState = {
  access_token: null,
  refresh_token: null,
  redirectUri: null,
}

const oAuthActions = {
  HANDLE_REDIRECT: 'HANDLE_REDIRECT',
  CLEAR_REDIRECT: 'CLEAR_REDIRECT',
}

function reducer (state = initialState, action) {
  let newState = {...state}
  switch (action.type) {
    case oAuthActions.HANDLE_REDIRECT:
      return { access_token: action.access_token, refresh_token: action.refresh_token, redirectUri: action.redirectUri }
    case oAuthActions.CLEAR_REDIRECT:
      newState.redirectUri = null
      return newState
    default:
      return state
  }
}

const doLogin = (jsforceOauth, config) => (state) => {
  window.location = jsforceOauth.getAuthorizationUrl({
    response_type: config.response_type,
    scope: config.scope,
    state: JSON.stringify(state)
  })
}

const clearRedirectUriAction = () => ({
  type: oAuthActions.CLEAR_REDIRECT
})

const parseToken = (dispatch) => () => {
  let query = new URLSearchParams(window.location.query)
  // http://localhost:3000/?error=OAUTH_APP_ACCESS_DENIED&error_description=user+is+not+admin+approved+to+access+this+app&state=%7B%22uri%22%3A%22%2F%22%7D
  if (query.error === 'OAUTH_APP_ACCESS_DENIED'){
    console.error(query.error_description)
    return Promise.reject()
  }
  let params = new URLSearchParams(window.location.hash.slice(1))
  let access_token = params.get('access_token')
  let refresh_token = params.get('refresh_token')
  let state = JSON.parse(params.get('state'))
  if (access_token) {
    return Promise.resolve(dispatch({
      type: oAuthActions.HANDLE_REDIRECT,
      access_token,
      refresh_token,
      redirectUri: state.uri
    }))
  } else {
    return Promise.reject()
  }
}

const getConnection = (oAuth2State) =>{
    let conn = new jsforce.Connection({
      oauth2,
      // logLevel: 'DEBUG',
      instanceUrl: (process.env.NODE_ENV === 'development' && !test_prod) ? 'https://chtc-ctlc--pc.my.salesforce.com' : 'https://chtc-ctlc.my.salesforce.com',
      accessToken: oAuth2State.access_token,
      refreshToken: oAuth2State.refresh_token,
    })
  return conn
}

export default (config) => {
  const jsforceOauth = new jsforce.OAuth2(config.oauth2)

  return {
    jsforceOauth,
    oAuthActions,
    reducer,
    parseToken,
    getConnection,
    clearRedirectUriAction,
    doLogin: doLogin(jsforceOauth, config)
  }
}
