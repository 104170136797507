/**
 * Created by Michał Stawski on 24.04.2020.
 */
import React from 'react'
import { Field } from 'formik'
import { CheckboxWithLabel, RadioGroup, TextField } from 'formik-material-ui'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { indexToOption, questionTypes } from './QuestionWidget'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { withStyles } from '@material-ui/core'

function QuestionInputText ({ question, fieldId }) {
  return <div>
    <Field
      component={TextField}
      type={question.type === questionTypes.INPUT_NUMBER.id ? 'number' : 'text'}
      multiline={question.type === questionTypes.INPUT_TEXTAREA.id}
      rows={4}
      name={fieldId}
    />
  </div>
}

function QuestionSelectOne ({ fieldId, question, isSubmitting }) {
  return <div>
    <Field component={RadioGroup} name={fieldId}
    >
      <FormLabel component="legend">
        Select one
      </FormLabel>
      {question.options && question.options.map((item, index) => (
        <FormControlLabel
          value={indexToOption(index)}
          control={<Radio disabled={isSubmitting}/>}
          label={item.text}
          disabled={isSubmitting}
        >

        </FormControlLabel>
      ))}
    </Field>
    {/*{item.other &&*/}
    {/*<Field*/}
    {/*  component={TextField}*/}
    {/*  type='text'*/}
    {/*  name={fieldId + '_other'}*/}
    {/*/>*/}
    {/*}*/}
  </div>
}

const styles = theme => ({
  optionLabel: {
    fontSize: '0.8rem'
  },
  optionsContainer:{
    justifyContent: 'space-around'
  }
})

const QuestionPointsGenerator = (options) =>
  withStyles(styles)(({ fieldId, question, isSubmitting, classes }) => {
      return <div>
        <Field component={RadioGroup} name={fieldId} row
               className={classes.optionsContainer}
        >
          {options.map((item, index) => (
            <FormControlLabel
              value={indexToOption(index)}
              control={<Radio disabled={isSubmitting}/>}
              label={item}
              disabled={isSubmitting}
              labelPlacement={'top'}
              classes={{ label: classes.optionLabel }}
            />
          ))}
        </Field>
      </div>
    }
  )

const QuestionMultipleValue = ({ fieldId, question }) => {
  return <div>
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Select all that apply
      </FormLabel>
      <FormGroup>
        {question.options.map((opt, index) => (
          <Field
            component={CheckboxWithLabel}
            type="checkbox" //REQUIRED to work with non-boolean options
            name={`${fieldId}[${index}]`}
            key={index}
            Label={{ label: opt.text }}
          />
        ))}
      </FormGroup>
    </FormControl>
  </div>
}
export { QuestionInputText, QuestionSelectOne, QuestionPointsGenerator, QuestionMultipleValue }
