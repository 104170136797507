/**
 * Created by Michał Stawski on 24.04.2020.
 */
import React from 'react'
import { Form, Formik } from 'formik'
import { indexToFieldId, questionTypes, QuestionWidget } from './QuestionWidget'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core'

function submitSurvey (values, { setSubmitting }) {
  console.log(values)
  setSubmitting(false)

}

/*
// select one **
Is your workplace:  (Select one)

A direct-operated youth centre (facility)
Probation office(s) (community)

// select one with other *
Please indicate your employment status:  (Select one)

Regular full-time
Regular part-time
Fixed term full-time
Fixed term part-time
Other employment status (please specify):

// number **
Please estimate the number of hours of your position carries a pager/cell phone on a monthly basis:
# of hours:


// text **
What is the cultural identity/affinity group that you most strongly identify with?


// text field **
What positive aspects of your workplace make it easier for you to deal with stress?

// block block
Please respond to the following questions in terms of your work environment:

// five point **
Is your work unevenly distributed so it piles up?

// 5 point frequency	**
How often is your manager/supervisor willing to listen to your problems at work?

Always
Often
Sometimes
Seldom
Never/Hardly Ever

// five point extent **
Does management trust employees to do their work well?

To a Very Large Extent
To a Large Extent
Somewhat
To a Small Extent
To a Very Small Extent


// five point time  **
How often have you slept badly and restlessly?

All of the time
A large part of the time
Part of the time
A small part of the time
Not at all

// 4point satisfaction **
Very Satisfied
Satisfied
Dissatisfied
Very Dissatisfied

// chose multiple *
rom whom?F  (Select all that apply)

Co-worker
Manager/supervisor
Subordinates
Youth/clients
 */

const questions = [
  {
    text: 'Is your workplace:',
    type: questionTypes.SELECT_ONE.id,
    options: [
      // TODO value or order
      {
        text: 'A direct-operated youth centre (facility)',
      },
      {
        text: 'Probation office(s) (community)',
      },
    ]
  },
  {
    text: ' Please indicate your employment status:',
    type: questionTypes.SELECT_ONE_WITH_OTHER.id,
    options: [
      // TODO value or order
      { text: ' Regular full-time ', },
      { text: ' Regular part-time ', },
      { text: ' Fixed term full-time ', },
      { text: ' Fixed term part-time ', },
      { text: ' Other employment status (please specify): ', other: true, otherType: questionTypes.INPUT_TEXT },
    ]
  },
  {
    text: 'From whom?',
    type: questionTypes.SELECT_MULTIPLE.id,
    options: [
      // TODO value or order
      { text: ' Co-worker ', },
      { text: ' Manager/supervisor ', },
      { text: ' Subordinates ', },
      { text: ' Youth/clients ', },
    ]
  },
  {
    text: 'Please estimate the number of hours of your position carries a pager/cell phone on a monthly basis',
    type: questionTypes.INPUT_NUMBER.id,
  },
  {
    text: 'What is the cultural identity/affinity group that you most strongly identify with?',
    type: questionTypes.INPUT_TEXT.id,
  },
  {
    text: ' What positive aspects of your workplace make it easier for you to deal with stress?',
    type: questionTypes.INPUT_TEXTAREA.id,
  },
  {
    text: 'How often is your manager/supervisor willing to listen to your problems at work?',
    type: questionTypes.FIVE_POINT_FREQUENCY.id,
  },
  {
    text: 'How often have you slept badly and restlessly?',
    type: questionTypes.FIVE_POINT_TIME.id,
  },
  {
    text: 'Does management trust employees to do their work well?',
    type: questionTypes.FIVE_POINT_EXTENT.id,
  },
  // TODO block of questions
]

const questionsToInitial = questionList => Object.fromEntries(questionList.map((item, index) => {

  switch (item.type) {
    case questionTypes.INPUT_TEXT.id:
      return [indexToFieldId(index), '']
    case questionTypes.INPUT_TEXTAREA.id:
      return [indexToFieldId(index), '']
    case questionTypes.SELECT_ONE.id:
      return [indexToFieldId(index), null]
    case questionTypes.SELECT_MULTIPLE.id:
      return [indexToFieldId(index), Array(item.options.length).fill(false)]
    case questionTypes.SELECT_ONE_WITH_OTHER.id:
      return [indexToFieldId(index), null]
    case questionTypes.FOUR_POINT_SATISFACTION.id:
      return [indexToFieldId(index), null]
    default:
      return [indexToFieldId(index), '']
  }
}))
/*
{ text: '
', },
*/

const styles = theme => ({
  formik: {
    margin: 'auto',
    width: 800
  },
  question: {
    padding: 10
  },
  submitButton: {
    margin: '10 0'
  }

})
const SurveyTab = ({ classes }) => (
  <Paper className={classes.formik}>
    <Formik initialValues={questionsToInitial(questions)}
            onSubmit={submitSurvey}
    >
      {/* TODO validation schema*/}
      {({ submitForm, isSubmitting }) => (
        <Form>
          {questions.map(
            (item, index) =>
              <QuestionWidget question={item} index={index} formProps={{}} key={index}
                              className={classes.question}/>)
          }
          <Button
            fullWidth
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  </Paper>
)

export default withStyles(styles)(SurveyTab)
