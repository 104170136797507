import React from 'react'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'
import {
  AppBar,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import { connect } from 'react-redux'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import { withRouter } from 'react-router'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { PrivateRoute } from '../common/components/privateRoute'
import headerImage from '../media/chtc_header_small.png'
import { oauth2 } from '../app/store'
import SurveyTab from '../features/survey/SurveyTab'

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  centeredTitle: {
    borderLeft: '0.1em solid grey',
    padding: '0.5em 0',
    marginLeft: '1em',
    flex: 1,
  },
  tooltip: {
    fontSize: theme.typography.fontSize * 1.5
  },
  userMenu: {
    top: 60
  },
  topMenu: {
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    textAlign: 'center',
    fontSize: 14,
    letterSpacing: '0.01em',
    height: 53,
    lineHeight: '53px',
    maxWidth: 900
  },
  topMenuLi: {
    color: 'black',
    textDecoration: 'none',
    display: 'inline-flex',
    margin: '0px 10px',
  },
  topMenuA: {
    color: 'black',
    textDecoration: 'none',
    fontSize: 'unset',
    textTransform: 'none',
  },
  topMenuDropdown: {
    color: 'black',
    fontSize: 14,
    textDecoration: 'none'
  },
  appBarStyle: {
    backgroundColor: 'white',
    // boxShadow: "0 7px 4px 0 rgba(0, 0, 0, 0.18)",
    // border: 'solid 1px #afafaf',
  },
  headerImage: {
    height: 40
  },
  versionInfo: {
    color: 'grey',
    fontSize: 9,
    textAlign: 'left',
  }
})

class Home extends React.Component {
  render () {
    return <div> Select App </div>
  }
}

// TODO proper router-config implementation
const routes = [
  {
    // component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
        title: 'Home',
        hidden: true,
        public: true
      },
      // {
      //   path: '/login/',
      //   exact: true,
      //   // component: LoginButton,
      //   component: LoginScreen,
      //   title: 'Home',
      //   hidden: true,
      //   public: true
      // },
      {
        path: '/tabs/home',
        component: Home,
        title: 'Home',
        icon: <HomeIcon/>,
        hidden: true,
        public: true
      },
      {
        path: '/tabs/surveys',
        component: SurveyTab,
        title: 'Surveys',
        icon: <PlaylistAddCheckIcon/>,
        hidden: false,
        public: true
      },
      // {
      //   path: '/tabs/pdfexport',
      //   component: PdfTab,
      //   title: 'PDF',
      //   icon: <PictureAsPdf/>,
      //   public: true
      // },
    ]
  }
]

class SimpleMenu extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render () {
    let {
      classes,
      hasPerm,

      // ...props
    } = this.props
    let items = []

    for (let route of routes[0].routes) {
      if (!route.hidden && (!route.perm || hasPerm(route.perm) || route.public)) {
        items.push(
          <MenuItem key={route.path} className={classes.menuItem} onClick={this.handleClose}
                    component={Link} to={route.path}>

            <ListItemIcon className={classes.icon}>
              {route.icon}
            </ListItemIcon>
            <ListItemText inset primary={route.title}/>
          </MenuItem>
        )
      }
    }

    return (
      <div>
        <Tooltip title="Apps" classes={{ tooltip: classes.tooltip }}>
          <Button
            aria-owns={this.state.open ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MenuIcon/>
          </Button>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleClose}
        >
          {items}
        </Menu>
      </div>
    )
  }
}

// class UserMenu extends React.Component {
//   state = {
//     anchorEl: null,
//     open: false,
//   }
//
//   handleClick = event => {
//     this.setState({ open: true, anchorEl: event.currentTarget })
//   }
//
//   handleClose = () => {
//     this.setState({ open: false })
//   }
//
//   render () {
//     let {
//       classes, authorization, userLogout,
//       // ...props
//     } = this.props
//
//     return (
//       <div>
//         <Tooltip title="User Menu" classes={{ tooltip: classes.tooltip }}>
//           <Button
//             aria-owns={this.state.open ? 'user-menu' : null}
//             aria-haspopup="true"
//             onClick={this.handleClick}
//           >
//             {authorization.user.profile.avatar ?
//               <Avatar src={authorization.user.profile.avatar}/> :
//               <Avatar>
//                 <AccountCircleIcon/>
//               </Avatar>
//             }
//           </Button>
//         </Tooltip>
//         <Menu
//           id="simple-menu"
//           anchorEl={this.state.anchorEl}
//           open={this.state.open}
//           onClose={this.handleClose}
//           className={classes.userMenu}
//         >
//           <MenuItem key="logout" className={classes.menuItem} onClick={userLogout}>
//             <ListItemIcon className={classes.icon}>
//               <EjectIcon/>
//             </ListItemIcon>
//             <ListItemText inset primary="Logout"/>
//           </MenuItem>
//         </Menu>
//       </div>
//     )
//   }
// }

// const mapStateToProps = (state) => ({ alerts: state.alerts })
// const ConnectedAlerts = connect(mapStateToProps)(Alerts)

// const alertsStyle = {
//   position: 'fixed',
//   // transform: 'translate(-50%, 0px)',
//   top: 30,
//   right: 0,
//   zIndex: 1200,
// }

function titleFromLocation (location) {
  let searchPath = location.pathname
  if (searchPath.endsWith('/')) {
    searchPath = searchPath.slice(0, -1)
  }
  for (let pathObj of routes[0].routes) {
    if (pathObj.path === searchPath) {
      return pathObj.title
    }
  }
  return ''
}

class Router extends React.Component {
  state = {
    toolsMenuOpen: null
  }

  componentDidMount () {
    if (!this.props.oauth2.access_token) {
      this.props.parseToken().then(() => {
          this.props.history.push({
            pathname: this.props.oauth2.redirectUri
          })
          this.props.dispatch(oauth2.clearRedirectUriAction())
        },
        () => oauth2.doLogin({
          uri: this.props.location.pathname
        })
      )
    }
  }

  render () {
    let {
      classes, authorization,
      hasPerm,
      // schema,
      location
      // ...props
    } = this.props
    // TODO while check login
    let loading = false

    let title = titleFromLocation(location)
    let routeComponents = []
    for (let route of routes[0].routes) {
      if (route.public) {
        routeComponents.push(
          <Route key={route.path} exact={Boolean(route.exact)} path={route.path} component={route.component}/>
        )
      } else {
        routeComponents.push(
          <PrivateRoute key={route.path} exact path={route.path} authorization={authorization}
                        component={route.component} perms={route.perm}/>
        )
      }
    }
    // only show any components after schema loads and token is refreshed

    // if (schema.error || schema.loading) {
    if (loading) {
      // TODO nicer loading screen
      return <div>
        loading...
      </div>
    }

    return (
      <div>
        <AppBar position="sticky">
          <Toolbar className={classes.appBarStyle}>
            <Tooltip title={'Home'} classes={{ tooltip: classes.tooltip }}>
              <Button component={Link} to="/tabs/home/" label="Home">
                <HomeIcon/>
              </Button>
            </Tooltip>

            <SimpleMenu className={classes.flex} classes={classes} hasPerm={hasPerm}/>
            <img src={headerImage} className={classes.headerImage} alt='header'/>


            <Typography className={classes.centeredTitle} variant="h6">
              {title}
            </Typography>

            <Typography className={classes.versionInfo} variant="caption">
              v {process.env.REACT_APP_VERSION}
            </Typography>
            {/*{authorization.loggedIn &&*/}
            {/*<UserMenu classes={classes} authorization={authorization} userLogout={userLogout}/>*/}
            {/*}*/}

          </Toolbar>
        </AppBar>

        {/*<ConnectedAlerts*/}
        {/*style={alertsStyle}/>*/}
        <Switch>
          {/*<Route key="/login" path="/login" component={LoginScreen}/>*/}
          {routeComponents}
        </Switch>

      </div>
    )
  }
}

const mapState = (state, ownProps) => {
  return {
    // location: state.router.location.pathname,
    oauth2: state.oauth2,
    /** @namespace state.authorization.user.perms **/
    hasPerm: (perm) => !perm || (state.authorization.user.perms && state.authorization.user.perms.indexOf(perm) !== -1),
  }
}

const mapDispatch = dispatch => ({
  parseToken: oauth2.parseToken(dispatch),
  dispatch,
  // userLogout: logout(dispatch),
  // reLogin: reLogin(dispatch),
  // getSchema: () => dispatch(schemaClientGet()),

})

const InnerRouter = withStyles(styles)(withRouter(connect(mapState, mapDispatch)(Router)))

export default props => (
  // same as homepage i package.json and urls.py
  <BrowserRouter basename='/'>
    <InnerRouter/>
  </BrowserRouter>
)
