import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import store from './app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createBrowserHistory } from 'history'
import { createMuiTheme } from '@material-ui/core'
import { create } from 'jss'
import jssExtend from 'jss-plugin-extend'
import { jssPreset, StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Router from './router/router'

class UTCMomentUtils extends MomentUtils {
  parse (value, format) {
    return this.moment.utc(value, format, true)
  }

  date (value) {
    return this.moment.utc(value)
  }
}

const browserHistory = createBrowserHistory(

)

const theme = createMuiTheme({})

// add JSS plugins
const jss = create({
  plugins: [...jssPreset().plugins, jssExtend()]
})

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Provider store={store}>
          <MuiPickersUtilsProvider
            utils={UTCMomentUtils}
            moment={moment}
            // locale?
            locale="en"
          >
            <Router history={browserHistory}/>
          </MuiPickersUtilsProvider>
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
