/**
 * Created by Michał Stawski on 05.05.2020.
 */
import React from 'react'
import { QuestionInputText, QuestionMultipleValue, QuestionPointsGenerator, QuestionSelectOne } from './surveyQustions'
import Typography from '@material-ui/core/Typography'

const questionTypes = {
  SELECT_ONE: {
    id: 'SELECT_ONE',
    class: QuestionSelectOne
  },
  SELECT_ONE_WITH_OTHER: {
    id: 'SELECT_ONE_WITH_OTHER',
    class: QuestionSelectOne
  },
  SELECT_MULTIPLE: {
    id: 'SELECT_MULTIPLE',
    class: QuestionMultipleValue
  },
  FIVE_POINT_FREQUENCY: {
    id: 'FIVE_POINT_FREQUENCY',
    class: QuestionPointsGenerator([
      'Always',
      'Often',
      'Sometimes',
      'Seldom',
      'Never/Hardly Ever',
    ])
  },
  FIVE_POINT_TIME: {
    id: 'FIVE_POINT_TIME',
    class: QuestionPointsGenerator([
      'All of the time',
      'A large part of the time',
      'Part of the time',
      'A small part of the time',
      'Not at all',
    ])
  },
  FIVE_POINT_EXTENT: {
    id: 'FIVE_POINT_EXTENT',
    class: QuestionPointsGenerator([
      'To a Very Large Extent',
      'To a Large Extent',
      'Somewhat',
      'To a Small Extent',
      'To a Very Small Extent',
    ])
  },
  FOUR_POINT_SATISFACTION: {
    id: 'FOUR_POINT_SATISFACTION',
    class: QuestionPointsGenerator([
      'Very Satisfied',
      'Satisfied',
      'Dissatisfied',
      'Very Dissatisfied',
    ])
  },
  INPUT_TEXT: {
    id: 'INPUT_TEXT',
    class: QuestionInputText
  },
  INPUT_TEXTAREA: {
    id: 'INPUT_TEXTAREA',
    class: QuestionInputText
  },
  INPUT_NUMBER: {
    id: 'INPUT_NUMBER',
    class: QuestionInputText
  },
}

const indexToFieldId = (index) => `field_${index}`
const indexToOption = (index) => `option_${index}`

const QuestionWidget = (props) => {
  const {
    question, index,
    className,
  } = props
  let QuestionClass = questionTypes[question.type].class

  return <div className={className}>
    <Typography>
      {index + 1}. {question.text}
    </Typography>
    {/*TODO add help text */}
    <QuestionClass question={question} fieldId={indexToFieldId(index)} />
  </div>
}

export { QuestionWidget, questionTypes, indexToFieldId, indexToOption }
